@import '~font-awesome/css/font-awesome.min.css';
@import '~react-block-ui/style.css';

.expand-cell-header,
.expand-cell {
  width: 3em !important;
  text-align: center;
}

.react-bootstrap-table-pagination {
  .pagination {
    justify-content: center;
  }
}

div.badge-valign {
  vertical-align: middle;

  .badge {
    vertical-align: middle;
    margin-top: -0.3em;
  }
}
